import io from 'socket.io-client';

console.log('Initializing socket connection to WebSocket server...');

const socket = io('wss://livestream.mcosta.tech/socket.io', {
    transports: ['websocket'],  // Ensure WebSocket transport is used
});

let peerConnections = {}; // Object to store all peer connections

export const setupWebRTC = (localStream, setRemoteStreams) => {
  console.log('Setting up WebRTC with localStream:', localStream);

  socket.on('new-participant', (participantId) => {
    console.log(`New participant connected: ${participantId}`);
    createPeerConnection(participantId, localStream, setRemoteStreams);
    socket.emit('offer', participantId); // Send offer to new participant
    console.log(`Offer sent to participant: ${participantId}`);
  });

  socket.on('offer', async (participantId, offer) => {
    console.log(`Received offer from participant: ${participantId}`, offer);
    const peerConnection = createPeerConnection(participantId, localStream, setRemoteStreams);
    await peerConnection.setRemoteDescription(offer);
    console.log(`Set remote description for participant: ${participantId}`);

    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);
    console.log(`Created and set local description (answer) for participant: ${participantId}`);

    socket.emit('answer', participantId, answer);
    console.log(`Answer sent to participant: ${participantId}`);
  });

  socket.on('answer', (participantId, answer) => {
    console.log(`Received answer from participant: ${participantId}`, answer);
    const peerConnection = peerConnections[participantId];
    peerConnection.setRemoteDescription(answer);
    console.log(`Set remote description (answer) for participant: ${participantId}`);
  });

  socket.on('candidate', (participantId, candidate) => {
    console.log(`Received candidate from participant: ${participantId}`, candidate);
    const peerConnection = peerConnections[participantId];
    peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    console.log(`Added ICE candidate for participant: ${participantId}`);
  });

  socket.on('disconnect', () => {
    console.log('Socket connection disconnected.');
  });
};

const createPeerConnection = (participantId, localStream, setRemoteStreams) => {
  console.log(`Creating peer connection for participant: ${participantId}`);

  const peerConnection = new RTCPeerConnection({
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
  });

  peerConnections[participantId] = peerConnection;

  localStream.getTracks().forEach(track => {
    peerConnection.addTrack(track, localStream);
    console.log(`Added track to peer connection for participant: ${participantId}`, track);
  });

  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      console.log(`ICE candidate generated for participant: ${participantId}`, event.candidate);
      socket.emit('candidate', participantId, event.candidate);
    }
  };

  peerConnection.ontrack = (event) => {
    console.log(`Received track from participant: ${participantId}`, event.streams);
    setRemoteStreams(prevStreams => [
      ...prevStreams,
      { id: participantId, stream: event.streams[0] }
    ]);
  };

  return peerConnection;
};

export const closeConnection = (participantId) => {
  if (peerConnections[participantId]) {
    console.log(`Closing peer connection for participant: ${participantId}`);
    peerConnections[participantId].close();
    delete peerConnections[participantId];
  }
};
