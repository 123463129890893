import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import VideoPlayer from './components/VideoPlayer';
import { setupWebRTC, closeConnection } from './webrtc/webrtcHandler';
import { FaVideo, FaMicrophone, FaVideoSlash, FaMicrophoneSlash } from 'react-icons/fa';

const App = () => {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const localVideoRef = useRef(null);

  // Function to initialize the local video/audio stream
  useEffect(() => {
    console.log("Attempting to access media devices...");

    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        console.log("Media devices accessed successfully:", stream);
        setLocalStream(stream);
        localVideoRef.current.srcObject = stream;
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
        alert("Error accessing media devices. Please check your permissions.");
      });

    // Cleanup when component unmounts
    return () => {
      console.log("Component unmounted, closing WebRTC connection.");
      closeConnection();
    };
  }, []);

  // Setup WebRTC once localStream is available
  useEffect(() => {
    if (localStream) {
      console.log("Local stream available, setting up WebRTC.");
      setupWebRTC(localStream, setRemoteStreams);
    } else {
      console.log("Local stream is not available yet.");
    }
  }, [localStream]);

  // Function to toggle the camera on/off
  const toggleCamera = () => {
    try {
      console.log("Toggling camera. Camera is currently:", isCameraOn ? "On" : "Off");
      localStream.getVideoTracks().forEach(track => track.enabled = !isCameraOn);
      setIsCameraOn(!isCameraOn);
      console.log("Camera is now:", isCameraOn ? "Off" : "On");
    } catch (error) {
      console.error("Error toggling camera:", error);
    }
  };

  // Function to toggle the microphone on/off
  const toggleMicrophone = () => {
    try {
      console.log("Toggling microphone. Microphone is currently:", isMicrophoneOn ? "On" : "Off");
      localStream.getAudioTracks().forEach(track => track.enabled = !isMicrophoneOn);
      setIsMicrophoneOn(!isMicrophoneOn);
      console.log("Microphone is now:", isMicrophoneOn ? "Off" : "On");
    } catch (error) {
      console.error("Error toggling microphone:", error);
    }
  };

  // Function to send a chat message
  const sendMessage = () => {
    try {
      console.log("Sending message:", message);
      setMessages(prevMessages => [...prevMessages, { text: message, user: 'Me' }]);
      setMessage('');
      console.log("Message sent successfully.");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="app-container">
      <div className="video-player-container">
        <VideoPlayer url="/hls/stream.m3u8" className="video-player" />
      </div>

      <div className="participants-container">
        <div className="local-video-section">
          <h2>Local Video</h2>
          <video ref={localVideoRef} autoPlay playsInline muted className="participant-video"></video>
        </div>
        {remoteStreams.map((stream, index) => (
          <div key={index} className="remote-video-section">
            <h2>Remote Video {index + 1}</h2>
            <video srcObject={stream} autoPlay playsInline className="participant-video"></video>
          </div>
        ))}
      </div>

      <div className="chat-section">
        <div className="chat-box">
          {messages.map((msg, idx) => (
            <p key={idx}><strong>{msg.user}: </strong>{msg.text}</p>
          ))}
        </div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>

      <div className="control-buttons">
        <button onClick={toggleCamera}>
          {isCameraOn ? <FaVideo /> : <FaVideoSlash />}
        </button>
        <button onClick={toggleMicrophone}>
          {isMicrophoneOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
        </button>
      </div>
    </div>
  );
};

export default App;
